import { css, DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack, Text, TextField } from "@fluentui/react";
import {
  DashboardGrid,
  IDashboardGridProps,
  IDashboardGridRef,
  JemConfiguration,
  PageHeading,
  PageStyles,
  LoadingStatus,
  LoadingSpinner,
  ScheduleJEResponseFromAPI,
  UserContext,
  TerminateRecurringJEResponse,
  exportToExcel,
  TerminateRecurringJEPayload,
  DashboardFilter,
  FilterSchema,
  FilterState,
  IIndexedTile
} from "@jem/components";

import React, { useContext, useEffect, useRef, useState } from "react";
import { getScheduleDetailsColumns } from "../../components/GLScheduleJE/ScheduleJE.Columns";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getRecurringSchedules,
  terminateRecurringSchedule,
  viewRecurringSchedule
} from "../../components/GLScheduleJE/ScheduleRecurringJE.Requests";
import { CoherenceModal } from "@coherence-design-system/controls";
import { OrderedMap } from "immutable";
import { RecurringScheduleFilterRows } from "../../components/GLScheduleJE/ScheduleRecurringJE.filterRows";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const isEqual = require("lodash/isEqual");

export interface RecurringScheduleJEProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
}

const dashboardFilters: FilterSchema<ScheduleJEResponseFromAPI>[] = [
  {
    filterName: "Next posting date",
    columnName: "nextPostingDate"
  },
  {
    filterName: "Frequency",
    columnName: "recurringFrequency"
  }
];

const RecurringScheduleJEPage: React.FC<RecurringScheduleJEProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const userContext = useContext(UserContext);

  const [gridItems, setGridItems] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Pending);
  const dashboardGridRef = useRef<IDashboardGridRef>(null);
  const [openTerminatePopup, setOpenTerminatePopup] = useState(false);
  const [terminateScheduleJeDetails, setTerminateScheduleJeDetails] = useState<ScheduleJEResponseFromAPI | null>();
  const [terminationComment, setTerminationComment] = useState<string>("");
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [messageDetails, setMessageDetails] = useState<TerminateRecurringJEResponse | null>(null);

  const [filteredItems, setFilteredItems] = useState<ScheduleJEResponseFromAPI[]>([]);
  const [filterState, setFilterState] = useState<FilterState | null>(null);

  useEffect(() => {
    if (filterState) {
      location.state = {
        filterState
      };
    }
  }, [filterState]);

  useEffect(() => {
    if (gridItems) {
      setFilteredItems(
        filterState?.filterBar ? RecurringScheduleFilterRows(filterState, dashboardFilters, gridItems) : gridItems
      );
    }
  }, [gridItems]);

  const filterItems = (newFilterState?: FilterState) => {
    if (!gridItems) {
      return;
    }
    if (newFilterState) {
      if (!isEqual(newFilterState, filterState)) {
        setFilterState(newFilterState);
        const newItems = RecurringScheduleFilterRows(newFilterState, dashboardFilters, gridItems);
        setFilteredItems(newItems);
      } else {
        setFilteredItems(gridItems);
      }
    } else {
      setFilteredItems(gridItems);
    }
  };
  const getGrid = async (mounted = true) => {
    try {
      const items: any = await getRecurringSchedules(props.configuration, userContext.accessToken);
      if (mounted) {
        setGridItems(items);
        setLoadingStatus(LoadingStatus.Resolved);
      }
    } catch (err) {
      setLoadingStatus(LoadingStatus.Rejected);
    }
  };

  useEffect(() => {
    let mounted = true;
    getGrid(mounted);
    return () => {
      mounted = false;
    };
  }, []);

  const onEditClick = (item: ScheduleJEResponseFromAPI) => {
    if (item.templateJeRefGUID) {
      navigate(`/gl/create?RefGuid=${item.templateJeRefGUID}&isRecurringTab=true&Edit=1`, {
        state: {
          origin: location.pathname,
          state: location.state
        }
      });
    } else {
      navigate(`/gl/create?TemplateId=${item.templateJeId}&isRecurringTab=true&fetchJeDraft=true`, {
        state: {
          origin: location.pathname,
          state: location.state
        }
      });
    }
  };

  const onViewClick = async (item: ScheduleJEResponseFromAPI) => {
    const response: { redirectPath: string } = await viewRecurringSchedule(
      props.configuration,
      userContext.accessToken,
      item.templateJeId
    );
    if (response.redirectPath) {
      navigate(`${response.redirectPath}${response.redirectPath.includes("create") ? "&isReadOnly=true" : ""}`, {
        state: {
          origin: location.pathname,
          state: location.state
        }
      });
    }
  };

  const onTerminateClick = (item: ScheduleJEResponseFromAPI) => {
    setTerminateScheduleJeDetails(item);
    setOpenTerminatePopup(true);
  };

  const onCloseTerminatePopup = () => {
    setTerminateScheduleJeDetails(null);
    setOpenTerminatePopup(false);
    setTerminationComment("");
  };

  const onTerminateConfirm = async () => {
    if (terminateScheduleJeDetails) {
      setLoadingStatus(LoadingStatus.Pending);
      setOpenTerminatePopup(false);
      try {
        const payload: TerminateRecurringJEPayload = {
          recurringJEScheduleId: terminateScheduleJeDetails.recurringJEScheduleId,
          comment: terminationComment,
          nextPostingDate: terminateScheduleJeDetails.nextPostingDate
        };
        const response: TerminateRecurringJEResponse = await terminateRecurringSchedule(
          props.configuration,
          userContext.accessToken,
          payload
        );
        setMessageDetails(response);
        setShowMessage(true);
        if (response.status === "Success") {
          getGrid();
        }
      } catch (err) {
        setLoadingStatus(LoadingStatus.Rejected);
      }
      setTerminationComment("");
    }
  };

  const columnGenerator: IDashboardGridProps["columnGenerator"] = getScheduleDetailsColumns({
    onViewClick,
    onEditClick,
    onTerminateClick
  });

  return (
    <>
      <div className={PageStyles.rootDiv}>
        <PageHeading>
          <h2>Recurring Schedule JE Dashboard</h2>
        </PageHeading>
        {showMessage ? (
          <MessageBar
            delayedRender={false}
            messageBarType={messageDetails?.status === "Success" ? MessageBarType.success : MessageBarType.error}
            dismissButtonAriaLabel="Close"
            onDismiss={() => {
              setMessageDetails(null);
              setShowMessage(false);
            }}
          >
            {messageDetails?.message}
          </MessageBar>
        ) : null}
        <div></div>
        {loadingStatus === LoadingStatus.Pending ? (
          <LoadingSpinner label={`Loading Recurring Schedule Details`} />
        ) : loadingStatus === LoadingStatus.Rejected ? (
          <Text
            variant="xLarge"
            style={{
              color: "var(--accent-font-color, black)"
            }}
          >
            Error Loading Recurring Schedule JE Details
          </Text>
        ) : (
          <>
            <div>
              <div
                className={css`
                  position: relative;
                  flex-grow: 1;
                  width: 100%;
                `}
              >
                <DashboardFilter<ScheduleJEResponseFromAPI>
                  filters={dashboardFilters}
                  singleSelectionFilters={[]}
                  searchEnabledFilters={["recurringScheduleName"]}
                  onFilterChanged={filterItems}
                  loadingStatus={loadingStatus}
                  tilesAndFilters={{
                    gridItems: gridItems,
                    tiles: OrderedMap<string, IIndexedTile>({}),
                    currentTile: ""
                  }}
                  location={location}
                />
              </div>
              <DashboardGrid
                columnGenerator={columnGenerator}
                isSortedIndex={10}
                items={filteredItems}
                isSortedDescending={true}
                customRef={dashboardGridRef}
                height={`500px`}
                onRenderRow={(props, defaultRender) => {
                  if (!defaultRender || !props) return null;
                  return (
                    <div>
                      {defaultRender({
                        ...props
                      })}
                    </div>
                  );
                }}
                onExport={(rows) => {
                  exportToExcel({
                    sheetName: "recurringJESchedules",
                    rowsWithHeader: rows,
                    fileName: "recurringJESchedules.xlsx"
                  });
                }}
                idForLocalStorage={""}
              ></DashboardGrid>
            </div>
          </>
        )}
      </div>
      <CoherenceModal
        isOpen={openTerminatePopup}
        modalWidth="small"
        height="responsive"
        // title={`Terminate schedule`}
        onDismiss={onCloseTerminatePopup}
        styles={{ root: { minHeight: "auto", maxHeight: "auto" } }}
      >
        <Stack>
          <div>Are you sure you want to terminate this schedule?</div>
          <TextField
            name="terminationComment"
            label="Comments"
            value={terminationComment}
            onChange={(_, newValue) => setTerminationComment(newValue || "")}
            multiline
            autoAdjustHeight
            rows={3}
            styles={{
              root: {
                marginTop: "10px",
                marginBottom: "10px"
              }
            }}
            required
          />
        </Stack>
        <Stack>
          <Stack horizontal styles={{ root: { padding: "20px 0" } }} tokens={{ childrenGap: 30 }}>
            <PrimaryButton
              text="Yes"
              onClick={onTerminateConfirm}
              ariaLabel="Yes"
              disabled={terminationComment.length === 0}
            />
            <DefaultButton
              text="No"
              onClick={onCloseTerminatePopup}
              ariaLabel="No"
              disabled={terminationComment.length === 0}
            />
          </Stack>
        </Stack>
      </CoherenceModal>
    </>
  );
};

RecurringScheduleJEPage.displayName = "RecurringScheduleJEPage";

export default RecurringScheduleJEPage;
